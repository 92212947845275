import React from 'react';
import { useDispatch } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

const useSnackBar = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleSnackBarError = (message?: any, duration?: number) => {
    let messageError = message ?? 'Desculpe, Ocorreu um erro inesperado.';
    if (message?.errors) {
      messageError =
        Object.values(message?.errors)?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      setErrorMessage(messageError);
    }
    dispatch(
      UIActionCreators.snackbarManagement(
        'error',
        messageError?.message ?? messageError,
        true,
        duration
      )
    );
    return;
  };

  const handleSnackBarWarning = (message?: any, duration?: number) => {
    dispatch(
      UIActionCreators.snackbarManagement('warning', message, true, duration)
    );
    return;
  };

  const handleSnackBarSuccess = (message?: any, duration?: number) => {
    dispatch(
      UIActionCreators.snackbarManagement('success', message, true, duration)
    );
    return;
  };

  const success = (message?: string, duration?: number) => {
    dispatch(
      UIActionCreators.snackbarManagement('success', message, true, duration)
    );
    return;
  };

  const error = (message?: any, duration?: number) => {
    let messageError = message ?? 'Desculpe, Ocorreu um erro inesperado.';
    if (message?.errors) {
      messageError =
        Object.values(message?.errors)?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      setErrorMessage(messageError);
    }
    dispatch(
      UIActionCreators.snackbarManagement('error', messageError, true, duration)
    );
    return;
  };

  return {
    errorMessage,
    handleSnackBarError,
    handleSnackBarSuccess,
    handleSnackBarWarning,
    success,
    error,
  };
};

export default useSnackBar;